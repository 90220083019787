import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20221104-Rate-Sheet.pdf";
const date = "04.11.2022";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    
        <h1 className="page-title">Changes to our mortgage products</h1>

        <br />
        <p>
          <strong>
            Following the Monetary Policy Committee announcement on Thursday 3 November 2022, the Bank of England Base
            Rate has increased from 2.25% to 3.00%.{" "}
          </strong>
        </p>
        <p>With effect from Friday 4 November 2022 the changes are as follows:</p>
        <ul>
          <li>All tracker rates will include the increased Bank of England Base Rate.</li>
          <li>There are no changes to our Residential Standard Variable Rate or Buy to Let Standard Variable Rate.</li>
        </ul>
        <p>An updated mortgage rate sheet will be published on Friday 4 November 2022 to reflect these changes.</p>

  </NewsArticleLayout>
);

export default Article;
